import { Component, OnInit, OnDestroy, AfterViewChecked, Inject, ChangeDetectorRef, Injector, AfterViewInit, HostBinding  } from '@angular/core';
import { takeUntil } from "rxjs/operators";
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { GLOBALS, Global } from '../../core/global';
import { AuthenticationService } from '@quicksuite/commons-auth-plugin';
import { LocalStorageService } from '../../shared/services/storage/local-storage.service';
import { ProgressBarService } from '../../shared/services/progress-bar/progress-bar.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { MenuRoutingService } from '../../../app/core/services/menu-routing.service';
import { Router, NavigationEnd } from '@angular/router';
import { RbacService } from "../../shared/services/rbac/rbac.service";
import { ApkNotificationService } from "@ak4a/components";
import { primaryNavLinks, adminNavLinks, userRoles, AppName, usrppPrimaryNavLinks, header} from '../../constants/constants';
import { TenantService } from '../../shared/services/tenant/tenant.service';
import { Title } from "@angular/platform-browser";
import { Tenant } from '../../shared/constants/constants';
import { SharepointService } from '../../shared/services/sharepoint/sharepoint';
import { staticdocuments } from '../../constants/staticdocuments';
import { CommunicationService } from '../../main/communication/communication.service';
import { viewFile } from '../../utils/helper';
import { ConfigurationService } from '../../shared/services/tenant/configuration.service';
import { FinancialInfoService } from '../../main/financial-info/financial-info.service';
import { APP_NAME as PARTNER_DOCS_APP_NAME } from '../../main/partner-docs/constants/constants';

@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss']
})

export class LandingLayoutComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  route;
  notificationService: ApkNotificationService;
  configService: ConfigurationService;
  spinnerService: ProgressBarService;
  localStorageService: LocalStorageService;
  menuRoutingService: MenuRoutingService;
  cdr: ChangeDetectorRef;
  authService: AuthenticationService;
  tenantService: TenantService;
  communicationService: CommunicationService;
  titleService: Title;
  financialInfoService: FinancialInfoService;
  tenant: any;
  menuObj = [
    {
      name: ' ',
      type: '',
      rbacPageName: '',
      route: '',
    }
  ];
  appName: string;
  CommonFooterContent: any;
  siteProvider = staticdocuments.siteProvider.file;
  mainHeader;
  setMenuData;
  stopApiCall = true;

  isPartnerDocs: boolean = false;

  constructor(@Inject(GLOBALS) public g: Global,
    private readonly rbacService: RbacService,
    private readonly router: Router,
    private readonly sharepointService: SharepointService,
    @Inject(I18NEXT_SERVICE) private readonly i18NextService: ITranslationService,
    private readonly injector: Injector) {

    this.authService = injector.get<AuthenticationService>(AuthenticationService);
    this.tenantService = injector.get<TenantService>(TenantService);
    this.communicationService = injector.get<CommunicationService>(CommunicationService);
    this.titleService = injector.get<Title>(Title);
    this.financialInfoService = injector.get<FinancialInfoService>(FinancialInfoService);
    this.notificationService = injector.get<ApkNotificationService>(ApkNotificationService);
    this.configService = injector.get<ConfigurationService>(ConfigurationService);
    this.spinnerService = injector.get<ProgressBarService>(ProgressBarService);
    this.localStorageService = injector.get<LocalStorageService>(LocalStorageService);
    this.cdr = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this.menuRoutingService = injector.get<MenuRoutingService>(MenuRoutingService);
    this.header.sideNavItems = this.menuRoutingService.AllMenuItems;
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.dynamicFooter();
      }
      if (this.setMenuData) {
        this.setMenuItems(this.setMenuData);
      }
    });
    window.addEventListener('resize', this.onResize.bind(this));

  }

  @HostBinding("class.theme-us") public usTheme: boolean;
  @HostBinding("class.theme-mx") public mxTheme: boolean;

  public get showNavigation(): boolean {
    return (!environment.useAuth || this.g.user && this.g.user.unique_name) ? true : false;
  }
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public navbarisActive = false;

  loading = false;
  openContactSupport = false;
  footerContent: any;

  profileItems: Object = {
    title: this.g.user.unique_name,
    type: 'dropdown',
    label: this.g.user.unique_name,
    items: [
      {
        name: this.i18NextService.t(`primaryNavLinks.Logout`),
        route: 'logout'
      }
    ]
  };

  infoItems: Array<Object> = [
    {
      title: "support",
      icon: "appkiticon icon-help-question-outline",
      type: 'link',
    }
  ];
  header = {
    infoItems: [],
    search: true,
    sideNavItems: [],
    primaryNavLinks: this.formatNavLinks(this.menuObj),
  };

  adminUser: boolean;
  ngOnInit(): void {
    this.enableThemes();
    this.isPartnerDocs = this.tenantService.isPartnerDocs();
    if (this.isPartnerDocs) return this.setUserAndAppName();

    this.tenant = this.tenantService.getTenant();

    this.configService.getTenantConfiguration().subscribe(res => {
      this.localStorageService.set('upasLinks', res);
    });
    this.configService.getAskQuestionEmail().subscribe(res => {
      this.localStorageService.set('sendMail', res);
    });
    this.configService.getCountries().subscribe(res => {
      this.localStorageService.set('Countries', res);
    });

    this.configService.getLinkName().subscribe(res => {
      this.localStorageService.set('linkNames', res);
    });

    if (this.tenant === Tenant.US) {
      this.appName = header.name;
      this.titleService.setTitle(AppName.US);
    } else {
      this.appName = AppName.MX;
      this.titleService.setTitle(AppName.MX);
    }


    this.authService.TryLogin();
    this.authService.getUserStream()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(u => this.g.user = u);
    let userName = this.g.user['name'] ? this.g.user['name'] : `${this.g.user['given_name']} ${this.g.user['family_name']}`;
    if (userName) {
      userName = userName.match(/\b(\w)/g).join('');
      this.profileItems['label'] = userName;
      this.getLoginUserDetails();
    }

  }

  enableThemes() {
    this.usTheme = this.tenantService.getTenant() === Tenant.US;
    this.mxTheme = this.tenantService.getTenant() === Tenant.MX;
  }
  
  setUserAndAppName() {
    this.appName = PARTNER_DOCS_APP_NAME;
    this.titleService.setTitle(PARTNER_DOCS_APP_NAME);
    let userName = this.g.user['name'] ? this.g.user['name'] : `${this.g.user['given_name']} ${this.g.user['family_name']}`;
    if (userName) {
      this.profileItems['label'] = userName.match(/\b(\w)/g).join('');
    }
  }

  bindClick(e) {
    if (this.tenantService.getTenant() === Tenant.US) {
      const userRole = this.localStorageService.get('userRole');
      if (e.title === 'support' && userRoles.adminUserUs !== userRole) {
        this.router?.navigate(['askaquestion']);
      }
    } else {
      if (e.title === 'support' && (!this.adminUser)) {
        this.openContactSupport = true;
      } else {
        this.openContactSupport = false;
      }
    }
  }

  checkUserAccept(role) {
    this.rbacService.getUserMaping('6267f5e9008b').subscribe((result: any) => {
      if (Object.keys(result).length && !result?.isTermAccepted && role !== userRoles.adminUser) {
        this.route = '/terms';
        this.router?.navigate(['terms'])
      }
    });
  }

  handleSupportModal(e) {
    this.openContactSupport = e;
  }

  public getLoginUserDetails() {
    this.route = this.router.url;
    this.rbacService.getLoginUserDetails().subscribe(
      (response: any) => {
        this.setMenuData = response;
        this.header.infoItems = this.setInfoMenu(response?.roles[0]?.mnemonic);
        this.checkUserAccept(response?.roles[0]?.mnemonic);
        this.setMenuItems(response);
      },
      (error) => {
        this.notificationService.error(
          'Failed to retrieve the data.',
          4000,
          false
        );
      }
    );
  }

  public setMenuItems(res) {
    this.localStorageService.set('userRole', res?.roles[0]?.mnemonic || null);
    this.localStorageService.set('userPermission', res.permissions || null);
    this.adminUser = (res?.roles[0]?.mnemonic === 'admin_user');
    const userRole = res?.roles[0]?.mnemonic;
    if (Object.keys(res).length !== 0) {
      this.composeMenuItems(res, userRole);
    } else {
      this.header.primaryNavLinks = [];
    }
  }

  public composeMenuItems(res, userRole) {
    let menuItemArray = [];
    for (const permission of res.permissions) {
      let singleMenu = this.tenant === Tenant.MX ? primaryNavLinks.find(
        (item) => item.rbacPageName === permission
      ) : usrppPrimaryNavLinks.find((item) => item.rbacPageName === permission);
      if (permission === 'view_financial_restricted') {
        singleMenu = primaryNavLinks.find(
          (item) => item.order === 3
        );
        singleMenu.items = singleMenu.items?.slice(0, 3);
      }
      if (singleMenu) {
        menuItemArray.push(singleMenu);
      }
      if (this.router.url === '/usrpphome') {
        menuItemArray.forEach((item, index) => {
          if (item.name !== "Admin" && item.name !== "Financial information" && item.name !== "Onboarding" && item.name !== "SPO" && item.name !== "Obituaries") {
            menuItemArray.splice(index, 1);
          }
        })
      }
      this.setAdminNavLink(menuItemArray, userRole, permission);
    }
    menuItemArray.sort(
      (firstMenuItem, secondMenuItem) =>
        parseFloat(firstMenuItem.order) - parseFloat(secondMenuItem.order)
    );
    this.menuObj = menuItemArray;
    this.header.primaryNavLinks = this.formatNavLinks(menuItemArray);
    this.fetchSharePointDoc(this.stopApiCall);
  }

  fetchSharePointDoc(value) {
    if (this.stopApiCall) {
      this.financialInfoService.getDocuments('cfdi', {})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          usrppPrimaryNavLinks.forEach((item, index) => {
            if (item.name === 'SPO') {
              usrppPrimaryNavLinks[index].route = result.uploadLink;
            }
          })
          this.header.primaryNavLinks.forEach((item, index) => {
            if (item.name === 'SPO') {
              item.route = result.uploadLink;
            }
          })
          this.stopApiCall = !value;
        }, (err) => {
          this.stopApiCall = value;
        });
    }
  }

  setAdminNavLink(menuItemArray, userRole, permission) {
    if (adminNavLinks.rbacPageName === permission && ((Tenant.MX && userRole === userRoles.adminUser)
      || (Tenant.US && (userRole === userRoles.adminUserUs || userRole === userRoles.requestor)))) {
      if (this.tenant !== Tenant.MX) {
        adminNavLinks.items = adminNavLinks.items.filter(item => item.name !== 'Support email' && item.name !== 'User mapping');
      }
      menuItemArray.push(adminNavLinks);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewChecked(): void {
    this.spinnerService.spinnerStatus.subscribe((val: boolean) => {
      if (val !== this.loading) {
        this.loading = val;
        this.cdr.detectChanges();
      }
    });
  }

  ngAfterViewInit(): void {
    console.log(document.getElementsByClassName('.apk-footer'));
    if (this.isPartnerDocs) {
      const headerAppTitle: any = document.querySelector('.apk-header .apk-header-logo-container a');
      if (headerAppTitle) headerAppTitle.style.pointerEvents = 'none'; // prevent navigation to home page
    } else if (this.tenant === Tenant.US) { // hide separator for USRPP
      const separator: any = document.querySelector('.apk-header .seperator');
      if (separator) {
        separator.setAttribute('style', 'margin: 5px 18px 5px 0px !important'); // using this to have !important
        separator.style.visibility = 'hidden';
      }
    }
  }

  formatNavLinks(links) {
    return links.map((link) => ({
      ...link,
      name: this.tenant === Tenant.MX ? this.i18NextService.t(`primaryNavLinks.${link.name}`) : link.name,
      ...(link.items && {
        items: this.formatNavLinks(link.items)
      })
    }))
  }


  

  dynamicFooter() {
    const route = this.router.url;
    if (this.tenantService.getTenant() === Tenant.MX) {
      switch (true) {
        case /financialinfo/.test(route):
          this.footerContent = this.i18NextService.t(`footer.financialContent`);
          this.CommonFooterContent = this.i18NextService.t(`footer.content`);
          break;
        case /tax/.test(route):
          this.footerContent = this.i18NextService.t(`footer.taxContent`);
          this.CommonFooterContent = this.i18NextService.t(`footer.content`);
          break;
        default:
          this.footerContent = this.i18NextService.t(`footer.genericContent`);
          this.CommonFooterContent = this.i18NextService.t(`footer.content`);
          break;
      }
    } else if (this.tenantService.isPartnerDocs()) {
      this.footerContent = this.i18NextService.t(`footer.APDContent`);
    } else {

      if (route === '/login') {
        this.CommonFooterContent = this.i18NextService.t(`footer.USLoginContent`);
      } else {
        this.CommonFooterContent = this.i18NextService.t(`footer.USCommonContent`);
      }
    }
  }

  downloadSiteProvider() {
    const data = { name: this.siteProvider[0].filename, employeeId: '', category: '', fileType: 'COMMON' }
    viewFile(this.communicationService, data);
  }

  onResize() {
    if (this.isPartnerDocs) return;
    setTimeout(() => {
      this.header.primaryNavLinks = this.formatNavLinks(this.menuObj);
    }, 0)
  }

  OpenPrivacyDocument() {
    this.sharepointService.downloadCommonFile('Privacy Statement.pdf');
  }

  showPrivacyLink() {
    return this.tenantService.getTenant() === Tenant.US;
  }

  setInfoMenu(val) {
    const nested = val ? this.infoItems : [];
    return val && (val === userRoles.adminUserUs || val === userRoles.adminUser || val === userRoles.requestor
      || val === userRoles.TSO_GAM || val === userRoles.onboardingReadonlyUs) ? [] : nested;
  }

    openCokieNotice() {
    const data = { name: this.siteProvider[1].filename, employeeId: '', category: '', fileType: 'COMMON' }
    viewFile(this.communicationService, data);
  }
}

