<span appActivityWatcher [resetActivityOnMouseClick]="true" [resetActivityOnMouseMove]="true"
  [inActivityTimeInMilliSeconds]="900000"></span>
<a tabindex="0" fragment="main" [routerLink]="'.'" class="sr-only sr-only-focusable skip-content">Skip to main
  content</a>
<div class="nav-template template-theme-one">
  <div class="fluid-container">
    <app-impersonate></app-impersonate>
    <apk-header [app-name]="appName" [info-items]="header.infoItems" [profile-items]="profileItems"
      [primary-nav-items]="header.primaryNavLinks" [single-line]="true" *ngIf="route !== '/terms' || route !== '/urlhandler'"
      (bindClick)="bindClick($event)">
    </apk-header>
    <div class="routes-container" role="main" id="main">
      <div class="container-fluid">
        <apk-notification extraClasses="example-class"></apk-notification>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <apk-footer *ngIf="route !== '/terms' || route !== '/urlhandler'" [dark]="false">
    <div [ngClass]="{'alignCenter': showPrivacyLink()}" [innerHTML]="footerContent"></div>
    <div [ngClass]="{'alignCenter': showPrivacyLink()}" class="pl-1" [innerHTML]="CommonFooterContent"></div>
    <div [ngClass]="{'alignCenter': showPrivacyLink()}" class="section pl-1" *ngIf="showPrivacyLink() === true">
      <ul>
        <li> <a class='higlight-text' [routerLink]="" (click)="OpenPrivacyDocument()"> Privacy </a> </li> |
        <li> <a class='higlight-text' [routerLink]="" (click)=openCokieNotice()> Cookie Statement </a> </li> |
        <li> <a class='higlight-text' [routerLink]="" (click)=downloadSiteProvider()> About Site Provider</a> </li>
      </ul>
    </div>
  </apk-footer>

  <oauth-pop-up></oauth-pop-up>
  <div *ngIf="loading">
      <div class="col-12 d-table">
        <div class="d-flex justify-content-center">
            <div class="a-p-10">
                <span class="a-loading a-primary loading-large"></span>
            </div>
        </div>
    </div>
  </div>
</div>
<app-contact-support [(modalDisplay)]="openContactSupport" (modalDisplayChange)="handleSupportModal($event)">
</app-contact-support>