export const staticdocuments = {
    Communication: {
        PartnetAffairs: {
            items: [
                { name: "partnerAffair1", filename: "Lanzamiento de portal de socios decanos" },
                { name: "partnerAffair2", filename: "Nueva imagen y link al portal de socios decanos -Declaraciones anuales" },
                { name: "partnerAffair3", filename: "Renovación de planes de seguros FY 23" },
                { name: "partnerAffair4", filename: "Condolencias - William Gordon Lee Tait" },
                { name: "partnerAffair5", filename: "Actualizaciones de arrendamientos de autos -iPads tabletas y celulares -Algunos requerimientos" },
                { name: "partnerAffair6", filename: "Nuevos socios decanos 1 julio 2020" },
                { name: "partnerAffair7", filename: "Sesión informativa para renovación de pólizas de gastos médicos" },
                { name: "partnerAffair8", filename: "Actualización sobre COVID-19 y cómo PwC enfrenta la situación" },
                { name: "partnerAffair9", filename: "Condolencias - Gildardo Lili" },
                { name: "partnerAffair10", filename: "Partner Affairs newsletter - Noviembre 18 2022" },
                { name: "partnerAffair11", filename: "Condolencias - Rogerio Casas Alatriste" },
                { name: "partnerAffair12", filename: "Agradecimiento a Jorge Alfredo y Antonio Salinas" },
                { name: "partnerAffair13", filename: "Invitación a sesión virtual sobre la Global CEO Survey - Capítulo México" },
                { name: "partnerAffair14", filename: "Agradecimiento a Brenda Vázquez" },
                { name: "partnerAffair15", filename: "Condolencias - Eduardo Clarke" },
                { name: "partnerAffair16", filename: "Partner Affairs newsletter - Marzo 31 2023" },
                { name: "partnerAffair17", filename: "Guia Enrollment FY24 - Socios Decanos" },
                { name: "partnerAffair18", filename: "Partner Announcement Day - 2023" },
                { name: "partnerAffair19", filename: "Socios promoción y admision, cambios en liderazgo" },
                { name: "partnerAffair20", filename: "Agradecimiento a Héctor Aguilar y Blas Montemayor" },
                { name: "partnerAffair21", filename: "Newsletter Socios Decanos Febrero 2024" },
                { name: "partnerAffair22", filename: "Newsletter Socios Decanos Abril 2024" },
                { name: "partnerAffair23", filename: "Nuevos socios activos, nuevos socios decanos y agradecimiento a Lázaro Peña" }


            ]
        },
        TechnicalDocuments: {
            items: [
                { name: "TechnicalDocument1", filename: "Nuevo contacto de autos en arrendamiento" },
                { name: "TechnicalDocument2", filename: "Condolencias Rogerio Casas Alatriste Urquiza" },
                { name: "TechnicalDocument3", filename: "Agradecimiento Claudia Solano-Nuevo socio decano Carlos Gradwohl" },
                { name: "TechnicalDocument4", filename: "¡Felices fiestas y feliz 2022!" },
                { name: "TechnicalDocument5", filename: "Suspención de arrendamientos de autos por periodo vacacional" },
                { name: "TechnicalDocument6", filename: "Portal de Socios Decanos México-Actualización a la política reservación de viajes" },
                { name: "TechnicalDocument7", filename: "Agradecimiento a Sandra Miranda por su retiro _Bienvenida Martha Hernández" },
                { name: "TechnicalDocument8", filename: "Nuevos socios decanos-Agradecimiento Esteban González y Guillermo Garau" },
                { name: "TechnicalDocument9", filename: "Agradecimiento Juan La Greca" },
                { name: "TechnicalDocument10", filename: "Procesos para renovación de planes de seguros para el FY22" },
                { name: "TechnicalDocument11", filename: "Próximo cambio de liderazgo en México" },
                { name: "TechnicalDocument12", filename: "Confirmación anual de actividades y cumplimiento al reglamento de socios decanos" },
                { name: "TechnicalDocument13", filename: "Información importante sobre sus SGMM" },
                { name: "TechnicalDocument14", filename: "Condolencias Carlos Montemayor" },
                { name: "TechnicalDocument15", filename: "Envío de documentos pago mensual-Tenencias 2021" },
                { name: "TechnicalDocument16", filename: "Condolencias Raúl Ramírez" },
                { name: "TechnicalDocument17", filename: "Importante-Registro de actividades-portal socios decanos" },
                { name: "TechnicalDocument18", filename: "Agradecimiento Luciano Scandolari" },
                { name: "TechnicalDocument19", filename: "Lanzamiento Portal Socios Decanos México" },
                { name: "TechnicalDocument20", filename: "Suspensión pago de cuotas a instituciones y colegios" },
                { name: "TechnicalDocument21", filename: "Agradecimiento Bryan Bloom" },
                { name: "TechnicalDocument22", filename: "Condolencias Octavio Delgado Bores" },
                { name: "TechnicalDocument23", filename: "¡Felices fiestas y feliz 2021!" },
                { name: "TechnicalDocument24", filename: "Solicitud de aprobación de actividades actividades Consult@ble NOM-035" },
                { name: "TechnicalDocument25", filename: "Condolencias Jorge Luis Hernández Baptista" },
                { name: "TechnicalDocument26", filename: "Agradecimiento a Francisco Javier Alonso Rodríguez" },
                { name: "TechnicalDocument27", filename: "Análisis del paquete económico 2021" },
                { name: "TechnicalDocument28", filename: "Agradecimiento Mauricio Garate" },
                { name: "TechnicalDocument29", filename: "Consultable precio preferencial para socios decanos" },
                { name: "TechnicalDocument30", filename: "Covid19 crisis Economic Scenarios for Mexico" },
                { name: "TechnicalDocument31", filename: "Global Consumer Insight 2020" },
                { name: "TechnicalDocument32", filename: "Boletín trimestral de inclusión financiera CNBV junio2020" },
                { name: "TechnicalDocument33", filename: "Información especial CFO Pulse Impactos financieros COVID-19" },
                { name: "Directory", filename: "Directorio" }
            ]
        }
    },
    Benifits: {
        items: [
            { name: "Benifits1", filename: "GNP Dentegra" },
            { name: "Benifits2", filename: "Dental and visual insurance guide" },
            { name: "Benifits3", filename: "Major Medical Insurance guide" },
            { name: "Benifits4", filename: "Medical report" },
            { name: "Benifits5", filename: "Notice of accident or illness" },
            { name: "Benifits6", filename: "Bank account for payment" },
            { name: "Benifits7", filename: "Accident or illness reimbursement" }
        ]
    },
    Pdp: {
        items: [
            { name: "Pdp1", filename: "PDP Withdrawal Voucher.pdf" },
            { name: "Pdp2", filename: "Maintain Your PDP Designated Assignee.pdf" }
        ]
    },
    Regulation: {
        items: [
            { name: "Regulation1", filename: "reglamento aplicable a socios decanos.pdf" }
        ]
    },
    ActivityReport: {
        items: [
            { name: "ActivityReport1", filename: "Dean Partners activity approval form" },
            { name: "ActivityReport2", filename: "Politica de Independencia" },
            { name: "ActivityReport3", filename: "GIP 5.18.pdf" },
            { name: "ActivityReport4", filename: "Independence guidance" }
        ]
    },
    USTaxation: {
        files: [
            { name: "USTaxtion1", filename: "2021 Tax Information FAQs - Retired Partners" },
            { name: "USTaxtion2", filename: "FAQ Estimated Tax Payment Basics" },
            { name: "USTaxtion3", filename: "My Escrow Account" },
            { name: "USTaxtion4", filename: "Net Investment Income Tax" },
            { name: "USTaxtion5", filename: "Reconciling_Schedule_A" },
            { name: "USTaxtion6", filename: "QRG - myConnection & Password Reset (RPP)" },
        ]
    },
    FAQ: {
        files: [
            { name: "FAQFile1", filename: "2021 Tax Information FAQs" },
            { name: "FAQFile2", filename: "Estimated Tax Payment Basics" },
            { name: "FAQFile3", filename: "My Escrow Account" },
            { name: "FAQFile4", filename: "Net Investment Income Tax" },
            { name: "FAQFile5", filename: "Reconciling Schedule A" },
            { name: "FAQFile6", filename: "QRG - myConnection & Password Reset" },
        ]
    },
    siteProvider: {
        file: [
            { name: "siteProvider", filename: "About Site Provider.pdf" },
            { name: "cookieNotice", filename: "Cookie Statement.pdf" }
        ]
    },
    shortcuts: {
        file: [
            { name: "shortcutFile1", filename: "Final Instructions Letter.pdf" },
            { name: "shortcutFile2", filename: "Technical help.pdf" },
            { name: "shortcutFile3", filename: "Income_Employment_Verification.pdf" },
            { name: "shortcutFile4", filename: "Other communications.pdf" },
            { name: "shortcutFile5", filename: "Resource sufficiency planning calculator.xlsm" },
        ]
    },
    partnerConnectivity: {
        file: [
            { name: "connectivityFile1", filename: "FAQs.pdf" },
            { name: "connectivityFile2", filename: "Key contacts.pdf" },
            { name: "connectivityFile3", filename: "Obituaries.pdf" },
            { name: "connectivityFile4", filename: "Retired partners committee.pdf" },
            { name: "connectivityFile5", filename: "My Discounts.pdf" },
            { name: "connectivityFile6", filename: "myVendor discounts.pdf" },
            { name: "connectivityFile7", filename: "My Business Activities.pdf" },
            { name: "connectivityFile8", filename: "PwC Leaders.pdf" },
            { name: "connectivityFile9", filename: "Matching Gift Program Details.pdf" },
            { name: "connectivityFile10", filename: "Women on Boards - Why It Matters" },
        ]
    },
};
